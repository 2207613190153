<template>
  <!-- 政策申报-挂载到index.vue-柱状图 -->
  <div class="com-container">
    <div class="com-chart" ref="enter_ref"></div>
  </div>
</template>

<script>
import { enterpriseServiceData } from "@/api/article";
export default {
  name: "EnterpriseService",

  data() {
    return {
      chartInstance: null, // eacharts的DOM节点
      allData: null, // 请求得数据
    };
  },
  created() {
    this.getDate(); // 获取数据
  },
  mounted() {
    this.initChart(); // 初始化echarts

    window.addEventListener("resize", this.screenAdapter);
    this.screenAdapter(); // 界面加载完成之后，主动进行屏幕适配
  },

  methods: {
    // 初始化echarts
    initChart() {
      this.chartInstance = this.$echarts.init(this.$refs.enter_ref);

      this.chartInstance.getZr().on("click", (parmas) => {
        let pointInPixel = [parmas.offsetX, parmas.offsetY];
        if (this.chartInstance.containPixel("grid", pointInPixel)) {
          let pointInPixel = [parmas.offsetX, parmas.offsetY];
          //点击第几个柱子
          let pointInGrid = this.chartInstance.convertFromPixel({ seriesIndex: 0 },pointInPixel);
          // 也可以通过params.offsetY 来判断鼠标点击的位置是否是图表展示区里面的位置
          // 也可以通过name[xIndex] != undefined，name是x轴的坐标名称来判断是否还是点击的图表里面的内容
          // x轴数据的索引
          let xIndex = pointInGrid[0];
          let iop = this.allData.filter((item, index) => {
            if (index == xIndex) {
              return item;
            }
          });
          window.open(iop[0].target_url);
        }
      });

      const initOption = {};
      this.chartInstance.setOption(initOption);
    },

    // 获取数据
    async getDate() {
      // //处理数据页数-结束
      try {
        const res = await enterpriseServiceData();
        this.allData = res.data.result;
      } catch (err) {
        console.log(err);
      }
      this.updateChart();
    },

    // 更新图表
    updateChart() {

      const colorArr3 = [
        "rgba(236, 162, 0)",
        "rgba(248, 189, 0)",
        // "rgba(224, 120, 0)",
      ];
      const color3 = {
        type: "linear",
        x: 0,
        x2: 1,
        y: 0,
        y2: 0,
        colorStops: [
          {
            offset: 0,
            color: colorArr3[0],
          },
          {
            offset: 0.5,
            color: colorArr3[0],
          },
          {
            offset: 0.5,
            color: colorArr3[1],
          },
          {
            offset: 1,
            color: colorArr3[1],
          },
        ],
      };
      const colorArr2 = ["#11a0eb", "#00b0eb", "#2570BB"];
      const color2 = {
        type: "linear",
        x: 0,
        x2: 1,
        y: 0,
        y2: 0,
        colorStops: [
          {
            offset: 0,
            color: colorArr2[0],
          },
          {
            offset: 0.5,
            color: colorArr2[0],
          },
          {
            offset: 0.5,
            color: colorArr2[1],
          },
          {
            offset: 1,
            color: colorArr2[1],
          },
        ],
      };

      const xAxisData = [];
      const data1 = []; // 蓝色数据
      const bottomData = []; // 蓝色底部数据
      const data2 = []; // 数据
      const bottomData2 = []; // 底部数据

      this.allData.forEach((element) => {
        xAxisData.push(element.label);
        data1.push(element.count);
        bottomData.push(1);
        data2.push(element.declare);
        bottomData2.push(1);
      });
      
      const option = {
        // backgroundColor: "rgba(203,193,55,0.18)",
        // 大标题
        // title: {
        //   text: "政策申报",
        //   // subtext: "副标题",
        //   left: "20%", //标题左右居中
        //   top: "5%",
        //   textStyle: {
        //     //主标题样式
        //     // fontSize: 30,
        //     fontweight: "bold",
        //     // lineHeight: "50",
        //   },
        //   // subtextStyle: {
        //   //   //副标题样式
        //   //   fontSize: 20,
        //   //   color: "rgba(255,0,0,1)",
        //   //   verticalAlign: "middle",
        //   // },
        //   show: true, //是否显示
        //   // padding: [12, 10, 6, 4], //标题内边距
        //   // itemGap: 20, //主副标题之间的间距
        //   right: "2%", //标题距离容器距离，
        // },
        // 图例列表

        legend: {
          left: "50%", // 左边距
          top: "3%",
          // orient: "horizontal", //排列方式（横着排列）vertical（竖着排列）
          // align: "right", //图例列表图在右边
          // itemGap: 20, //每个图例之间的水平间距
          z: 99,
          // itemHeight: 18,
          selectedMode: false, // 是都可以通过点击改变图列的显示状态
          inactiveColor: "rgba(255, 223, 223, 1)", // 图列关闭时的颜色
          textStyle: {
            // color: "rgba(0, 194, 255)",
            // fontStyle: "italic",
            // fontWeight: "bold",
            // padding: [3, 3, 3, 3],
            color: "rgba(255, 255, 255)",
          },
          // pageTextStyle: { // 图例页信息的文字样式
          //   color: null,
          // },
          // animation: false,  // 图例翻页是否使用动画
          // selectorLabel: {
          //   show: false,
          // },
        },
        tooltip: {
          trigger: "axis",
          confine: true, // tooltip限制在图表里面
          backgroundColor: "#01224f",
          borderColor: "#0189d5",
          textStyle: {
            color: "#fff",
            // fontSize: 12,
          },
          formatter: function (params) {
            // 不能删，提示
            let str = params[0].name + ":"; // 标题的名字
            params.filter(function (item) {
              // console.log(item.componentSubType); //外面可以拿到item.color,只要在if条件item.componentSubType == "bar"里面就拿不到了，或者item.componentSubType == "pictorialBar",可以拿到
              if (item.componentSubType == "bar") {
                // 在bar里面可以拿到item.seriesName(符合企业数量) item.value（值）
                let p = "";
                for (let i = 0; i < item.color.colorStops.length; i++) {
                  const element = item.color.colorStops[i];
                  // console.log(element);
                  if (element !== null) {
                    // 判断里面是否为空
                    p = element;
                    break;
                  }
                }
                // console.log(p.color); //可以打印出结果
                str +=
                  "<br/>" +
                  "<span style='display:inline-block;width:10px;height:10px;border-radius:10px;margin-right:3px;background-color:" + p.color +";'></span>" +
                  item.seriesName +
                  "：" +
                  item.value; // 拼接数据
              }
            });
            return str;
          },
          axisPointer: {
            // 提示x轴上文字-企业名字
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        grid: {
          x: "2%",
          x2: "2%",
          y: "20%",
          y2: "2%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'], //测试------有真数据要删除
          // data: sellerNames
          data: xAxisData,
          nameGap: 50,
          // 更改坐标轴颜色
          // axisLine: {
          // lineStyle: {
          // color: this.fontColorX,
          // color: "#393e5a",
          // },
          // onZero: true,
          // },
          // x轴的字体样式
          axisLabel: {
            // interval: 0,
            // textStyle: {
              // color: this.fontColorX, // 更改坐标轴文字颜色
              color: "#fff", // 文字颜色
              // fontSize: 16, // 更改坐标轴文字大小
              fontFamily: "MicrosoftYaHei",
              // top: 50,
            // },
            // X轴lable的处理函数，如果x轴的lable有空格，比如:['AM点检 异常', 'PM巡检 异常', 'PM保养 异常'],这里会以空格为分割，显示两行
            formatter: function (params) {
              return params.split(" ").join("\n");
              //                console.log(' formatter',params);
            },
          },
          axisTick: {
            show: true, // 是否显示坐标轴刻度
            inside: true, // 坐标轴刻度是否朝内，默认朝外
          },
        },
        yAxis: {
          type: "value",
          // color:'rgba(255, 255, 255)',
          name: "单位：个",
          nameLocation: "end", // 坐标轴名称显示位置。('start''middle''center''end')
          // sellerNames: sellerValues,
          // position: "left", // 默认 grid 中的第一个 y 轴在 grid 的左侧（'left'），第二个 y 轴视第一个 y 轴的位置放在另一侧。
          // nameGap: 20,
          nameTextStyle: {
            color: "#fff",
            // fontSize: '12'
          },
          axisTick: {
            show: false, // 是否显示坐标轴刻度
            inside: true, // 坐标轴刻度是否朝内，默认朝外
            length: 1, // 坐标轴刻度的长度
            // lineStyle: {
            // color: "#fff", // 刻度线的颜色
            // width: '28', // 坐标轴刻度线宽
            // type: "solid", // 坐标轴线线的类型（solid实线类型；dashed虚线类型；dotted点状类型）
            // },
          },

          // axisTick: {
          //   show: true, // 是否显示坐标轴刻度
          //   inside: true, // 坐标轴刻度是否朝内，默认朝外
          //   length: 5, // 坐标轴刻度的长度
          //   lineStyle: {
          //     color: "black", // 刻度线的颜色
          //     width: 10, // 坐标轴刻度线宽
          //     type: "solid", // 坐标轴线线的类型（solid实线类型；dashed虚线类型；dotted点状类型）
          //   },
          // },

          axisLabel: {
            // textStyle: {
              // color: this.fontColorY, // 更改坐标轴文字颜色
              color: "#fff",
              // fontSize: 12, // 更改坐标轴文字大小
              fontFamily: "MicrosoftYaHei",
            // },
          },
          // axisLabel: {
          //   show: true, // 是否显示刻度标签
          //   interval: 0, // 坐标轴刻度标签的显示间隔，在类目轴中有效.0显示所有
          //   inside: false, // 刻度标签是否朝内，默认朝外
          //   rotate: 0, // 刻度标签旋转的角度，在类目轴的类目标签显示不下的时候可以通过旋转防止标签之间重叠；旋转的角度从-90度到90度
          //   // margin: 10, // 刻度标签与轴线之间的距离
          //   // formatter 刻度标签的内容格式器，支持字符串模板和回调函数两种形式
          //   color: "black", // 刻度标签文字的颜色
          //   fontStyle: "normal", // 字体的风格（normal无样式；italic斜体；oblique倾斜字体）
          //   fontFamily: "MicrosoftYaHei",
          //   // 字体的粗细（normal无样式；bold加粗；bolder加粗再加粗；lighter变细；数字定义粗细也可以取值范围100至700）
          //   fontWeight: "bold",
          //   fontSize: "12", // 文字字体大小
          //   // align: "left", // 文字水平对齐方式，默认自动（left/center/right）
          //   // verticalAlign: "left", // 文字垂直对齐方式，默认自动（top/middle/bottom)
          //   lineHeight: "50", // 行高
          //   backgroundColor: "rgba(0,0,0,0)", // 文字块背景色，例：#123234, red, rgba(0,23,11,0.3)
          // },

          // 更改坐标轴颜色
          axisLine: {
            lineStyle: {
              color: "#242b4a",
            },
          },
          // 网格线
          splitLine: {
            // 网格线
            lineStyle: {
              type: "solid",
              with: 1.5,
              color: "#242b4a",
            },
          },

          // 分隔
          // splitArea: {
          //   show: false, // 是否显示分隔区域
          //   interval: "0", // 坐标轴刻度标签的显示间隔，在类目轴中有效.0显示所有
          //   areaStyle: {
          //     // color分隔区域颜色。分隔区会按数组中颜色顺序依次循环设置颜色。默认是一个深浅的间隔色
          //     color: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
          //     opacity: 1, // 图形透明度。支持从0到1的数字，为0时不绘制该图形
          //   },
          // },
        },
        series: [
          // {
          // type: 'bar',
          //   // data: [120, 200, 150, 80, 70, 110, 130],  //测试-------有真数据要删除
          // }

          // 蓝色-数据底下的圆片
          {
            name: "",
            type: "pictorialBar",
            symbolOffset: ["-69%", "50%"], // 蓝色底部坐标
            // symbolSize: [barWidth - 4, (20 * (barWidth - 4)) / barWidth], //3d立体效果
            // symbolSize: [66,27],  //3d立体效果
            z: 1,
            symbol: "diamond", // 蓝色底部形状-菱形
              itemStyle: {
              opacity: 1,
              color: color2, // 蓝色底部颜色
              // color: 'transparent'
            },

            data: bottomData,
          },

          // 蓝色-数据的柱状图
          {
            name: "符合企业数",
            type: "bar",
            // barWidth: barWidth,
            itemStyle: {
              // lenged文本
              opacity: 1, // 这个是 透明度
              color: color2, // LinearGradient：指明蓝色-颜色渐变方向
            },
            data: data1,
          },

          // 替代柱状图 默认不显示颜色，是最下方柱图（邮件营销）的value值 - 20
          // {
          //   type: "bar",
          //   symbol: "diamond",
          //   barWidth: barWidth + -50,
          //   itemStyle: {
          //     color: "transparent",
          //   },
          //   data: data1,
          // },

          // 蓝色-数据顶部的样式
          {
            name: "",
            type: "pictorialBar",
            symbol: "diamond", // 蓝色数据顶部形状-零菱形，默认是原型
            symbolOffset: ["-65%", "-50%"], // 蓝色数据顶部坐标
            // symbolSize: [barWidth, 23],
            z: 3,
            markLine: {
              // 设置平均值线样式
              data: [
                {
                  name: "平均线",
                  type: "average", // 虚线显示平均线
                  // lineStyle: { //蓝色-平均线默认样式
                  //   //警戒线的样式  ，虚实  颜色
                  //   type: "dashed",
                  // },
                  emphasis: {
                    // 蓝色-鼠标线移动后样式
                    lineStyle: {
                      // 警戒线的样式  ，虚实  颜色
                      type: "average",
                    },
                  },
                  label: {
                    // 平均线字体样式
                    show: true,
                    // position: 'insideEndTop',
                    position: "middle",
                    formatter: "符合企业数平均值：{c}家", // 单位
                    // fontweight: "bold",
                    color: "#00b0eb",
                    // fontSize: 16,
                  },
                },
              ],
            },

            itemStyle: {
              // 设置蓝色顶部样式
              // normal: {
                opacity: 1,
                // color: colorArr[2],
                color: "#0076c0",
              //   label: {
              //     show: true, // 开启显示
              //     position: "top", // 在上方显示
              //     textStyle: {
              //       // 数值样式
              //       color: "#fff",
              //       // fontSize: 16,
              //       // top: 50,
              //     },
              //   },
              // },
            },
            // symbol: 'image://https://tse3-mm.cn.bing.net/th/id/OIP-C.hjSiAnHgTmnQMkmZTUXMRgAAAA?w=170&h=178&c=7&r=0&o=5&pid=1.7',
            symbolPosition: "end",
            data: data1,
          },

          // 阴影的顶部
          // {
          //   name: "", // 头部
          //   type: "pictorialBar",
          //   symbol: "diamond",
          //   symbolOffset: ["0%", "-50%"],
          //   symbolSize: [barWidth, 10],
          //   z: 12,
          //   symbolPosition: "end",
          //   itemStyle: {
          //     color: "rgba(18, 47, 133,0.5)",
          //     opacity: 1,
          //     borderWidth: 1,
          //     borderColor: "rgba(18, 47, 133,1)",
          //   },
          //   data: topData,
          // },

          // 后面的背景
          // {
          //   name: "2019",
          //   type: "bar",
          //   barWidth: barWidth,
          //   barGap: "-100%",
          //   z: 1,
          //   itemStyle: {
          //     color: "rgba(18, 47, 133,0.3)",
          //   },
          //   data: topData,
          // },

          // 柱状条上的文字
          // {
          //   type:'effectScatter',
          //   markPoint: {
          //     symbol: 'https://tse3-mm.cn.bing.net/th/id/OIP-C.hjSiAnHgTmnQMkmZTUXMRgAAAA?w=170&h=178&c=7&r=0&o=5&pid=1.7',
          //     symbolSize: [barWidth, 5],//图片大小
          //     symbolKeepAspect: true,
          //     symbolOffset:[0,-20],//图片相对于柱形图x,y轴偏移量，注意要往上移y要是负的
          //     label:{
          //       show:false, //顶部默认会展示y轴的值，可以设置false,不展示
          //     }
          //   }
          // }

          // 第二个柱状图
          {
            name: "企业申报数",
            type: "bar",
            // barWidth: barWidth,
            itemStyle: {
              // lenged文本
              opacity: 1, // 这个是 透明度
              color: color3, // LinearGradient：指明颜色渐变方向
            },
            data: data2, // 柱状图
          },
          // 黄色-数据底下的圆片
          {
            name: "",
            type: "pictorialBar",
            symbolOffset: ["68%", "50%"],
            // symbolSize: [barWidth - 4, (20 * (barWidth - 4)) / barWidth],
            // symbolSize: [66,27],  //3d立体效果
            z: 9,
            symbol: "diamond",
            itemStyle: {
              opacity: 1,
              color: color3,
              // color: 'transparent'
            },
            data: bottomData2,
          },
          // 黄色-数据顶部的样式
          {
            name: "",
            type: "pictorialBar",
            symbol: "diamond",
            symbolOffset: ["64%", "-50%"],
            // symbolSize: [barWidth, 23],
            z: 3,
            // markPoint: {
            //   data: [
            //     { name: 'Max', value: 182.2, xAxis: 7, yAxis: 183 },
            //     { name: 'Min', value: 2.3, xAxis: 11, yAxis: 3 },
            //   ],

            // },
            markLine: {
              // -平均线样式
              data: [
                {
                  name: "平均线",
                  type: "average",
                  // lineStyle: {
                  //   // 普通默认显示样式
                  //   type: "dashed",
                  // },
                  emphasis: {
                    // 鼠标移动之后样式
                    lineStyle: {
                      type: "average",
                    },
                  },
                  label: {
                    // -平均线文字样式
                    show: true,
                    // position: 'insideEndTop',
                    position: "middle",
                    formatter: "企业申报平均值：{c}家", // 单位
                    fontweight: "bold",
                    color: "#fec200",
                    // fontSize:16,
                  },
                },
              ],
              // label: {
              //   show: true,
              //   // position: 'insideEndTop',
              //   position: 'middle',
              //   formatter: '企业申报平均值：{c}家',//单位
              //   fontweight: "bold",
              //   color: '#fff',
              //   // fontSize:16,
              // }
            },
            itemStyle: {
              // normal: {
                opacity: 1,
                // color: colorArr[2],
                // color: "rgba(254, 194, 0, 1)",
                color: "rgba(200, 142, 0, 1)",
                // label: {
                //   show: true, // 开启显示
                //   position: "top", // 在上方显示
                //   textStyle: {
                //     // 数值样式
                //     color: "black",
                //     fontSize: 16,
                //     top: 50,
                //   },
                // },
              // },
            },
            // symbol: 'image://https://tse3-mm.cn.bing.net/th/id/OIP-C.hjSiAnHgTmnQMkmZTUXMRgAAAA?w=170&h=178&c=7&r=0&o=5&pid=1.7',
            symbolPosition: "end",
            data: data2,
          },
        ],
      };

      this.chartInstance.setOption(option);
    },

    // 屏幕适配，浏览器大小变化，调用。完成屏幕适配
    screenAdapter() {
      const titleFontSize = (this.$refs.enter_ref.offsetWidth / 100) * 2.35; // 30
      const adapterOption = {
        // 大标题
        title: {
          // textStyle: {
            // 主标题样式
            fontSize: titleFontSize,
          // },
        },
        // 图例列表
        legend: {
          itemGap: titleFontSize, // 每个图例之间的水平间距
          itemHeight: titleFontSize * 0.7,
          textStyle: {
            fontSize: titleFontSize * 1.2,
            padding: [
              titleFontSize / 10,
              titleFontSize / 10,
              titleFontSize / 10,
              titleFontSize / 10,
            ],
          },
        },
        tooltip: {
          textStyle: {
            fontSize: titleFontSize / 0.8,
          },
        },
        xAxis: {
          type: "category",
          // x轴的字体样式
          axisLabel: {
            // textStyle: {
              fontSize: titleFontSize * 1.1, // 更改坐标轴文字大小
              top: titleFontSize / 0.6,
            // },
          },
        },
        yAxis: {
          type: "value",
          nameGap: titleFontSize * 2,
          nameTextStyle: {
            fontSize: titleFontSize * 1.1,
          },
          axisLabel: {
            // textStyle: {
              fontSize: titleFontSize * 1.1, // 更改坐标轴文字大小
            // },
          },
        },
        series: [
          // 蓝色-数据底下的圆片
          {
            type: "pictorialBar",
            symbolSize: [titleFontSize * 1.28, titleFontSize * 0.9],
          },

          // 蓝色-数据的柱状图
          {
            type: "bar",
            barWidth: titleFontSize / 0.7, // 调整柱子宽度
          },

          // 蓝色-数据顶部的样式
          {
            type: "pictorialBar",
            symbolSize: [titleFontSize / 0.75, 10],
            markLine: {
              label: {
                fontSize: titleFontSize * 1.2,
              },
            },
            itemStyle: {
              // normal: {
                label: {
                  textStyle: {
                    fontSize: titleFontSize / 1.875,
                    top: titleFontSize / 0.6,
                  },
                },
              // },
            },
          },

          // 第二个柱状图
          {
            type: "bar",
            barWidth: titleFontSize / 0.7, // 调整柱子宽度
          },
          // 黄色-数据底下的圆片
          {
            type: "pictorialBar",
            symbolSize: [titleFontSize * 1.3, titleFontSize * 0.9],
          },
          // 黄色-数据顶部的样式
          {
            type: "pictorialBar",
            symbolSize: [titleFontSize / 0.72, 10],
            markLine: {
              label: {
                fontSize: titleFontSize * 1.2,
              },
            },
          },
        ],
      };
      this.chartInstance.setOption(adapterOption); //
      this.chartInstance.resize(); // 手动调用，才能产生效果再次调用resize()方法
    },
  },
};
</script>

<style lang="less" scoped>
</style>
