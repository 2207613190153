<template>
  <div class="big_box yra">
    <img src="../assets/images/0.jpg" alt="图片加载失败" />
    <header>
      <div class="left">
        <span>{{this.day}}</span>
        <span>{{this.time}}</span>
        <span>{{this.nowWeek}}</span>
      </div>
      <div class="right">
        <span>
          <div :class="sort"></div>
          <div>{{this.address}}</div>
        </span>
        <span>
          <span>温度:&nbsp;{{xiAnWeatherDate.temp}}</span>
          <div>湿度:&nbsp;{{xiAnWeatherDate.humidity}}</div>
        </span>
        <span>
          <span>{{xiAnWeatherDate.weather}}</span>
          <div>{{xiAnWeatherDate.windDirection}}{{xiAnWeatherDate.windPower}}</div>
        </span>
      </div>
      <div class="right_two" id="handover" @click="handoverBtn">
        <div>房源</div>
        <div>数据</div>
      </div>
    </header>
    <!-- 主体内容 -->
    <div class="bigBox">
      <section>
        <div class="TrendBox" id="Trend_Box">
          <!-- 企业服务 -->
          <Trend></Trend>
        </div>
        <div class="EnterpriseServiceBox">
          <!-- 政策申报 -->
          <EnterpriseService></EnterpriseService>
        </div>
        <div>
          <!-- 服务企业 -->
          <div class="listBox">
            <List></List>
          </div>
        </div>
      </section>

      <section>
        <div class="middleBox">
          <!-- 中间内容 -->
          <div class="one" @click="oneBtn">
            <span>
              <span>{{TotalData.company_total}}</span>
              <span>家</span>
            </span>
            <span>企业总数</span>
          </div>
          <div class="two" @click="twoBtn">
            <span>
              <span>{{TotalData.declare_total}}</span>
              <span>条</span>
            </span>
            <span>可申报政策总数</span>
          </div>
          <div class="three" @click="threeBtn">
            <span>
              <span>{{TotalData.services_total}}</span>
              <span>项</span>
            </span>
            <span>可提供企业服务数</span>
          </div>

          <div class="four" @click="fourBtn">
            <span>{{TotalData.company_total}}</span>
            <span>中小企业</span>
          </div>

          <div class="numberOne" @click="numberOneBtn">
            <!-- <span>{{allData[0].total}}</span>
            <span>{{allData[0].hangye_two}}</span> -->
            <span>{{allDataNumber[0]}}</span>
            <span>{{allDataName[0]}}</span>
          </div>
          <div class="numberTwo" @click="numberTwoBtn">
            <span>{{allDataNumber[1]}}</span>
            <span>{{allDataName[1]}}</span>
          </div>
          <div class="numberThree" @click="numberThreeBtn">
            <span>{{allDataNumber[2]}}</span>
            <span>{{allDataName[2]}}</span>
          </div>
          <div class="numberFour" @click="numberFourBtn">
            <span>{{allDataNumber[3]}}</span>
            <span>{{allDataName[3]}}</span>
          </div>
          <div class="numberFive" @click="numberFiveBtn">
            <span>{{allDataNumber[4]}}</span>
            <span>{{allDataName[4]}}</span>
          </div>
          <div class="numberSix" @click="numberSixBtn">
            <span>{{allDataNumber[5]}}</span>
            <span>{{allDataName[5]}}</span>
          </div>
          <div class="numberSeven" @click="numberSevenBtn">
            <span>{{allDataNumber[6]}}</span>
            <span>{{allDataName[6]}}</span>
          </div>
          <div class="numberEight" @click="numberEightBtn">
            <span>{{allDataNumber[7]}}</span>
            <span>{{allDataName[7]}}</span>
          </div>
          <div class="numberNine" @click="numberNineBtn">
            <span>{{allDataNumber[8]}}</span>
            <span>{{allDataName[8]}}</span>
          </div>

        </div>
      </section>

      <section>
        <div class="FamousBox">
          <!-- 区域分布 -->
          <Famous></Famous>
        </div>
        <div class="OperatingBox">
          <!-- 注册资本 -->
          <Operating></Operating>
        </div>
        <div class="PeopleBox">
          <!-- 规模人数 -->
          <People></People>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Trend from "@/components/Trend.vue";
import EnterpriseService from "@/components/enterpriseService.vue";
import Famous from "@/components/Famous.vue";
import Operating from "@/components/operating.vue";
import People from "@/components/people.vue";
import List from "@/components/list.vue";
import { SortData,TotalData } from '@/api/article';
import { weatherData } from '@/api/index';
export default {
  name: "Home",
  components: {
    Trend,
    EnterpriseService,
    Famous,
    Operating,
    People,
    List,
  },
  data() {
    return {
      allData: [], //企业分类数据
      allDataNumber: [], //企业数量
      allDataName: [],//企业名字
      TotalData: [], //企业总计数据
      xiAnWeatherDate: [], //天气+日期
      date: null,
      day: null,//日期      //请求
      time: null, //时间        //js
      address: null,//地址      //请求
      weatherIconfont: null,//天气        //请求
      nowWeek: null, //周几   //js
      skipUrl: null, //企业总数
      policyUrl: null,//政策申报总数
      enterpriseService: null, //企业服务总数
      classification: null, //行业分类
    };
  },

  computed: {
    sort: function() {
      // 最终方案
      let see = {
        '阴': 'iconfont icon-tianqitubiao_yin',
        '晴': 'iconfont icon-tianqitubiao_qing',
        '霾': 'iconfont icon-a-6-wumai',
        '多云': 'iconfont icon-tianqitubiao_duoyun',
        '雨': 'iconfont icon-yu',
        '小雨': 'iconfont icon-tianqitubiao_xiaoyu',
        '中雨': 'iconfont icon-tianqitubiao_zhongyu',
        '大雨': 'iconfont icon-tianqitubiao_dayu',
        '小雪': 'iconfont icon-tianqitubiao_xiaoxue',
        '中雪': 'iconfont icon-tianqitubiao_zhongxue',
        '大雪': 'iconfont icon-tianqitubiao_daxue',
        '雾': 'iconfont icon-tianqitubiao_wu',
        '扬沙': 'iconfont icon-icon_yangsha',
        '雨夹雪': 'iconfont icon-yujiaxue1',
        '浮尘': 'iconfont icon-icon-test',
        '无数据': 'iconfont icon-wushuju', //暂无数据'
      }[this.weatherIconfont]
      return see
    }

    // ES6写法，本页面缺点：异步请求慢，页面显示两次图标问题
    //   const weatherIcons = {
    //     '阴': 'iconfont icon-tianqitubiao_yin',
    //     '晴': 'iconfont icon-tianqitubiao_qing',
    //     '霾': 'iconfont icon-a-6-wumai',
    //     '多云': 'iconfont icon-tianqitubiao_duoyun',
    //     '雨': 'iconfont icon-yu',
    //     '小雨': 'iconfont icon-tianqitubiao_xiaoyu',
    //     '中雨': 'iconfont icon-tianqitubiao_zhongyu',
    //     '大雨': 'iconfont icon-tianqitubiao_dayu',
    //     '小雪': 'iconfont icon-tianqitubiao_xiaoxue',
    //     '中雪': 'iconfont icon-tianqitubiao_zhongxue',
    //     '大雪': 'iconfont icon-tianqitubiao_daxue',
    //     '雾': 'iconfont icon-tianqitubiao_wu',
    //     '扬沙': 'iconfont icon-icon_yangsha',
    //     '雨夹雪': 'iconfont icon-yujiaxue1',
    //     '浮尘': 'iconfont icon-icon-test',
    //   }
    //   const defaultIcon = 'iconfont icon-wushuju'; // 默认图标
    //   let icon = weatherIcons[this.weatherIconfont] ?? defaultIcon; // 对象取值，若无数据则使用默认值
    //   return icon;
    // }  
  },

  mounted() {
    this.XiAnSortData() //企业分类
    this.XiAnSortTotal() //企业总计
    this.xiAnAWeatherData()//天气+日期接口
    this.timer() //需求：js获取时间，让时间动态化
    setInterval(() => {
      if (this.time.length == 0 && time == '') {
          console.log('获取时间失败');
      }else {
        this.timer()
        this.setNowTimes()
      }
    }, 1000);
    this.init()
  },
  destroyed() {
    clearInterval(this.setInterval);
  },

  methods: {
    handoverBtn() {
        this.$router.push({path:"/housingList",})
    },
    init() {
      // this.Instance = document.querySelector('#Trend_Box')
      // console.log(Instance);
    },
    async XiAnSortData() {//企业分类
      try {
        const res = await SortData()
        this.allData = res.data.result
        this.allData.forEach((item)=> {
          if (item) {
            this.allDataNumber.push(item.total);
            this.allDataName.push(item.hangye_two);
            return
          }
        })
      } catch (error) {
        console.log(error);
      }
    },
    async XiAnSortTotal() { //企业总计
      try {
        const res = await TotalData()
        // console.log(res);
        // this.skip_Url = res.data.result.company_target_url
        // console.log(skip_Url);
        this.TotalData = res.data.result //页面数据
        // console.log(this.TotalData);

        this.skipUrl = this.TotalData.company_target_url //企业总数
        // console.log(this.skipUrl);
        
        this.policyUrl = this.TotalData.declare_target_url //政策申报
        // console.log(policyUrl);

        this.enterpriseService = this.TotalData.services_target_url //企业服务总数
        // console.log(this.enterpriseService);

        // console.log(this.TotalData);

        // this.aggregate = this.TotalData.company_total
        // console.log(this.aggregate);
      } catch (error) {
        console.log(error);
      }
    },
    async xiAnAWeatherData() {
      try {
        const res = await weatherData()
        // console.log(res);
        this.xiAnWeatherDate = res.data.data
        // console.log(this.xiAnWeatherDate);
        this.date = this.xiAnWeatherDate.reportTime //天气所有数据
        this.day = this.xiAnWeatherDate.reportTime.slice(0,10) //日期
        // this.time = this.xiAnWeatherDate.reportTime.slice(11,19) //时间
        this.address = this.xiAnWeatherDate.address.slice(4,8) //地址
        this.weatherIconfont = this.xiAnWeatherDate.weather //天气文字
        // if(this.xiAnWeatherDate.weather = "阴"){
        //   this.weatherIconfont == '' //阴天
        // }
        // console.log(this.weatherIconfont);
        // console.log(this.weatherIconfont);
        // console.log(this.address);
        // console.log(this.day);
        // console.log(this.time);
        // console.log(this.date);
      } catch (error) {
        console.log(error);
      }
    },
    timer() {
        //获取当前时间
        var date = new Date();
        //年 getFullYear()：四位数字返回年份
        // var year = date.getFullYear(); //getFullYear()代替getYear()
        //月 getMonth()：0 ~ 11
        // var month = date.getMonth() + 1;
        // 日 getDate()：(1 ~ 31)
        // var day = date.getDate();
        //时 getHours()：(0 ~ 23)
        var hour = date.getHours();
        //分 getMinutes()： (0 ~ 59)
        var minute = date.getMinutes();
        //秒 getSeconds()：(0 ~ 59)
        var second = date.getSeconds();
        // this.time = '当前时间是：' + year + '-' + this.addZero(month) + '-' + this.addZero(day) + ' ' + this.addZero(hour) + ':' + this.addZero(minute) + ':' + this.addZero(second);
        // console.log(this.time);
        this.time = this.addZero(hour) + ':' + this.addZero(minute) + ':' + this.addZero(second);
        // console.log(this.time);
        // let jkl = this.time
        // return time;
    },
    //小于10的拼接上0字符串
    addZero(s) {
        return s < 10 ? ('0' + s) : s;
    },
    setNowTimes () {  
	  	//获取当前时间
	  	let myDate = new Date()
	  	let wk = myDate.getDay()
	  	let yy = String(myDate.getFullYear())
	  	let mm = myDate.getMonth() + 1
	  	let dd = String(myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate())
	  	let hou = String(myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours())
	  	let min = String(myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes())
	  	let sec = String(myDate.getSeconds() < 10 ? '0' + myDate.getSeconds() : myDate.getSeconds())
	  	let weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
	  	let week = weeks[wk]
	  	this.nowDate = yy + '年' + mm + '月' + dd
	  	this.nowTime = hou + ':' + min + ':' + sec
	  	this.nowWeek = week
	  },

    //企业总数
    oneBtn() {
      window.open(this.skipUrl)
    },
    //政策申报总数
    twoBtn() {
      window.open(this.policyUrl)
    },
    // 企业服务总数
    threeBtn() {
      window.open(this.enterpriseService)
    },
    //中小企业
    fourBtn() {
      window.open(this.skipUrl)
    },
    //商务服务业
    numberOneBtn() {
      window.open(this.allData[0].target_url)
    },
    //软件和信息技术服务业
    numberTwoBtn() {
      window.open(this.allData[1].target_url)
    },
    //互联网和相关服务
    numberThreeBtn() {
      window.open(this.allData[2].target_url)
    },
    //道路运输业
    numberFourBtn() {
      window.open(this.allData[3].target_url)
    },
    //房屋建筑业
    numberFiveBtn() {
      window.open(this.allData[4].target_url)
    },
    //文化艺术业
    numberSixBtn() {
      window.open(this.allData[5].target_url)
      // window.location.href = this.allData[5].target_url
    },
    //其他服务业
    numberSevenBtn() {
      window.open(this.allData[6].target_url)
      // window.location.href = this.allData[6].target_url
    },
    //教育
    numberEightBtn() {
      window.open(this.allData[7].target_url)
      // window.location.href = this.allData[7].target_url
    },
    //租赁业
    numberNineBtn() {
       window.open(this.allData[8].target_url)
      // window.location.href = this.allData[8].target_url
    },
  },

}
</script>

<style lang="less" scoped>
.big_box {
  position: relative;
  width: 100%;
  height: 100%;
  color: #fff;
  img {
    width: 100% !important;
    height: 100% !important;
  }
}
header {
  .left {
    position: absolute;
    top: 1.71%;
    left: 4.8%;
    width: 15.5%;
    height: 4.6%;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32.6%;
      height: 95%;
      color: rgba(0, 173, 226);
      // font-size: 16px;
      font-size: 1.5vh;
      cursor:pointer;
    }
  }
  .right {
    position: absolute;
    top: 1.71%;
    right: 4.8%;
    width: 15.5%;
    height: 4.6%;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      width: 100%;
      height: 100%;
      cursor:pointer;
      >span {
        display: inline-block;
        height: 50%;
        font-size: 1.5vh;
        color: rgba(0, 173, 226);
        text-align: center;
      }
      div:nth-child(1) {
        text-align: center;
        height: 50%;
        // font-size: 25px;
        font-size: 2vh;
      }
      div:nth-child(2) {
        text-align: center;
        height: 50%;
        color: rgba(0, 173, 226);
        // font-size: 16px;
        font-size: 1.5vh;
      } 
    }
  }
  .right_two {
      position: absolute;
      top: 1.7%;
      right: 1.5%;
      width: 2.5%;
      height: 45px;
      border: 1px solid #00c8bf;
      box-shadow: inset 0px 0px 5px 2px #00c8bf;
      font-weight: 700;
      font-size: 0.9rem;
      line-height: 22px;
      // font-size: 14px;
      // line-height: 20px;
      div {
        text-align: center;
      }
  }
  .quit {
    position: absolute;
    top: 2%;
    right: 1.2%; //1.71
    width: 2.2%; //2.5
    height: 3.8%; //4.4
    border: 2px solid rgba(0, 119, 205);
    border-radius: 4px 4px;
    box-shadow: 0.5px 0.5px 5px 1px #0076cd inset; //0.5px 0.5px 10px 2.5px #284285 inset
    // color: rgba(0, 173, 226);
    background-color: black;
    font-size: 14px;
    font-weight: 700;

    span:nth-child(1) {
      display: block;
      height: 38%;
      border-radius: 4px 4px;
      text-align: center;
      margin-top: 4px;
      color: #21bcbc;
    }
    span:nth-child(2) {
      display: block;
      height: 50%;
      border-radius: 4px 4px;
      text-align: center;
      margin-bottom: 2px;
      color: rgba(0, 130, 201); //0, 173, 226
    }
  }
}
.bigBox {
  .TrendBox {
    position: absolute;
    top: 16%;
    left: 1.7%;
    width: 22.5%;
    height: 18%;
  }
  .EnterpriseServiceBox {
    position: absolute;
    top: 42.9%;
    left: 2.3%;
    width: 21.5%;
    height: 24%;
  }
  .listBox {
    position: absolute;
    top: 75.2%;
    left: 2.5%;
    width: 20.5%;
    height: 16.2%;
  }
  //中间
  .middleBox {
    span {
      display: block;
    }
    .one {
      position: absolute;
      top: 9.7%;
      left: 32.2%;
      width: 6.3%;
      height: 5.2%;
      text-align: center;
      color: rgba(0, 218, 138);
      cursor:pointer;

      span:nth-child(1) {
        height: 50%;
        // font-size: 24px;
        // font-size: 2vh;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        span:nth-child(1) {
          height: 100%;
          // font-size: 30px;
          font-size: 3vh;
        }
        span:nth-child(2) {
          // font-size: 14px;
          font-size: 1.4vh;
          margin-top: 12%;
          margin-left: 2%;
        }
      }
      span:nth-child(2) {
        height: 50%;
        display: flex;
        align-items: end;
        justify-content: center;
        // font-size: 12px;
        font-size: 1vh;
      }
    }
    .two {
      position: absolute;
      top: 9.7%;
      left: 47%;
      width: 6.3%;
      height: 5.2%;
      text-align: center;
      color: rgba(253, 176, 37);
      cursor:pointer;

      span:nth-child(1) {
        height: 50%;
        // font-size: 24px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        span:nth-child(1) {
          height: 100%;
          // font-size: 30px;
          font-size: 3vh;
        }
        span:nth-child(2) {
          // font-size: 14px;
          font-size: 1.4vh;
          margin-top: 14%;
          margin-left: 2%;
        }
      }
      span:nth-child(2) {
        height: 50%;
        display: flex;
        align-items: end;
        justify-content: center;
        font-size: 1vh;
      }
    }
    .three {
      position: absolute;
      top: 9.7%;
      left: 61.5%;
      width: 6.3%;
      height: 5.2%;
      text-align: center;
      color: rgba(251, 31, 146);
      cursor:pointer;

      span:nth-child(1) {
        height: 50%;
        // font-size: 24px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        span:nth-child(1) {
          height: 100%;
          // font-size: 30px;
          font-size: 3vh;
        }
        span:nth-child(2) {
          // font-size: 14px;
          font-size: 1.4vh;
          margin-top: 16%;
          margin-left: 2%;
        }
      }
      span:nth-child(2) {
        height: 50%;
        display: flex;
        align-items: end;
        justify-content: center;
        // font-size: 12px;
        font-size: 1vh;
      }
    }

    .four {
      position: absolute;
      top: 32.5%;
      left: 47%;
      width: 6.3%;
      height: 5.2%;
      text-align: center;
      color: rgba(211, 231, 247);
      cursor:pointer;

      span:nth-child(1) {
        height: 50%;
        // font-size: 24px;
        font-size: 2.6vh;
        font-weight: 700;
      }
      span:nth-child(2) {
        height: 50%;
        margin-top: 2px;
        font-weight: 700;
        // font-size: 18px;
        font-size: 1.8vh;
      }
    }

    .numberOne {
      position: absolute;
      top: 52.85%;
      left: 36%;
      width: 4.5%;
      height: 5.4%;
      text-align: center;
      color: rgba(4, 197, 224);
      cursor:pointer;

      span:nth-child(1) {
        height: 50%;
        // font-size: 24px;
        font-size: 2.47vh;
        font-weight: 700;
        color: rgba(236, 163, 46);
      }
      span:nth-child(2) {
        height: 50%;
        font-weight: 700;
        // font-size: 15px;
        font-size: 1.48vh;
      }
    }
    .numberTwo {
      position: absolute;
      top: 52.85%;
      left: 43.8%;
      width: 4.5%;
      height: 6.2%;
      text-align: center;
      color: rgba(4, 197, 224);
      cursor:pointer;

      span:nth-child(1) {
        height: 44%;
        // font-size: 24px;
        font-size: 2.47vh;
        font-weight: 700;
        color: rgba(236, 163, 46);
      }
      span:nth-child(2) {
        height: 66%;
        font-weight: 700;
        // font-size: 15px;
        font-size: 1.48vh;
        overflow:hidden;

        text-overflow:ellipsis;

        display:-webkit-box;

        -webkit-box-orient:vertical;

        -webkit-line-clamp:2;
      }
    }
    .numberThree {
      position: absolute;
      top: 52.85%;
      left: 51.6%;
      width: 4.5%;
      height: 5.4%;
      text-align: center;
      color: rgba(4, 197, 224);
      cursor:pointer;

      span:nth-child(1) {
        height: 50%;
        // font-size: 24px;
        font-size: 2.47vh;
        font-weight: 700;
        color: rgba(236, 163, 46);
      }
      span:nth-child(2) {
        height: 50%;
        font-weight: 700;
        // font-size: 15px;
        font-size: 1.48vh;
      }
    }
    .numberFour {
      position: absolute;
      top: 52.85%;
      left: 59.4%;
      width: 4.5%;
      height: 6.4%;
      text-align: center;
      color: rgba(4, 197, 224);
      cursor:pointer;

      span:nth-child(1) {
        height: 40%;
        // font-size: 24px;
        font-size: 2.47vh;
        font-weight: 700;
        color: rgba(236, 163, 46);
      }
      span:nth-child(2) {
        height: 60%;
        font-weight: 700;
        // font-size: 15px;
        font-size: 1.48vh;
        overflow:hidden;

        text-overflow:ellipsis;

        display:-webkit-box;

        -webkit-box-orient:vertical;

        -webkit-line-clamp:2;

      }
    }
    .numberFive {
      position: absolute;
      top: 76.55%;
      left: 32.1%;
      width: 4.5%;
      height: 5.4%;
      text-align: center;
      color: rgba(4, 197, 224);
      cursor:pointer;

      span:nth-child(1) {
        height: 50%;
        // font-size: 24px;
        font-size: 2.47vh;
        font-weight: 700;
        color: rgba(236, 163, 46);
      }
      span:nth-child(2) {
        height: 50%;
        font-weight: 700;
        // font-size: 15px;
        font-size: 1.48vh;
      }
    }
    .numberSix {
      position: absolute;
      top: 76.55%;
      left: 39.9%;
      width: 4.5%;
      height: 5.4%;
      text-align: center;
      color: rgba(4, 197, 224);
      cursor:pointer;

      span:nth-child(1) {
        height: 50%;
        // font-size: 24px;
        font-size: 2.47vh;
        font-weight: 700;
        color: rgba(236, 163, 46);
      }
      span:nth-child(2) {
        height: 50%;
        font-weight: 700;
        // font-size: 15px;
        font-size: 1.48vh;
      }
    }
    .numberSeven {
      position: absolute;
      top: 76.55%;
      left: 47.7%;
      width: 4.5%;
      height: 5.4%;
      text-align: center;
      color: rgba(4, 197, 224);
      cursor:pointer;

      span:nth-child(1) {
        height: 50%;
        // font-size: 24px;
        font-size: 2.47vh;
        font-weight: 700;
        color: rgba(236, 163, 46);
      }
      span:nth-child(2) {
        height: 50%;
        font-weight: 700;
        // font-size: 15px;
        font-size: 1.48vh;
      }
    }
    .numberEight {
      position: absolute;
      top: 76.55%;
      left: 55.5%;
      width: 4.5%;
      height: 5.4%;
      text-align: center;
      color: rgba(4, 197, 224);
      cursor:pointer;

      span:nth-child(1) {
        height: 50%;
        // font-size: 24px;
        font-size: 2.47vh;
        font-weight: 700;
        color: rgba(236, 163, 46);
      }
      span:nth-child(2) {
        height: 50%;
        font-weight: 700;
        // font-size: 15px;
        font-size: 1.48vh;
      }
    }
    .numberNine {
      position: absolute;
      top: 76.55%;
      left: 63.33%;
      width: 4.5%;
      height: 5.4%;
      text-align: center;
      color: rgba(4, 197, 224);
      cursor:pointer;

      span:nth-child(1) {
        height: 50%;
        // font-size: 24px;
        font-size: 2.47vh;
        font-weight: 700;
        color: rgba(236, 163, 46);
      }
      span:nth-child(2) {
        height: 50%;
        font-weight: 700;
        // font-size: 15px;
        font-size: 1.48vh;
        letter-spacing: -2px;
      }
    }
  }

  .FamousBox {
    position: absolute;
    top: 13.3%;
    right: 1.3%;
    width: 22.6%;
    height: 23.7%;  
  }
  .OperatingBox {
    position: absolute;
    top: 43%;
    right: 1.3%;
    width: 22.7%;
    height: 24.2%;
    // border: 1px solid red;
  }
  .PeopleBox {
    position: absolute;
    top: 74.3%;
    right: 1.6%;
    width: 23%;
    height: 19%;
  }
}

</style>