<template>
  <div class="com-container">
    <div class="com-chart ijn" ref="operating_ref"></div>
  </div>
</template>

<script>
import { operatingData, TotalData } from "@/api/article";
export default {
  name: "Trend",

  data() {
    return {
      chartInstance: null,
      allData: null,
      TotalData: null,
      titleFontSize: null,
    };
  },

  mounted() {
    this.initChart();
    this.getData();
    window.addEventListener("resize", this.screenAdapter);
    this.screenAdapter();
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.screenAdapter);
  },

  // watch() {
    
  // },

  methods: {
    initChart() {
      this.chartInstance = this.$echarts.init(this.$refs.operating_ref);

      this.chartInstance.getZr().on("click", (parmas) => {
        let iop = this.allData.filter((item) => {
          return item.count == parmas.topTarget.style.text;
        });
        window.open(iop[0].target_url);
      });
      
      const initOption = {};
      this.chartInstance.setOption(initOption);
    },

    async getData() {
      try {
        const res = await operatingData();
        this.allData = res.data.result;
      } catch (error) {
        console.log(error);
      }
      try {
        const res = await TotalData();
        this.TotalData = res.data.result.company_total;
      } catch (error) {
        console.log(error);
      }
      this.upDateChart();
    },

    upDateChart() {
      // 中心坐标
      const centerArr = [
        ["15%", "23%"],
        ["50%", "23%"],
        ["85%", "23%"],
        ["85%", "73%"],
        ["50%", "73%"],
        ["15%", "73%"],
      ];
      const colorArr = [
        ["#fd472b", "#fe7a34"],
        ["#f6167b", "#e98b5d"],
        ["#e4c033", "#38c2d9"],
        ["#420deb", "#cf1ffc"],
        ["#007aec", "#00d4f7"],
        ["#009641", "#97cd41"],
      ];
      const seriesArr = this.allData.map((item, index) => {
        return {
          type: "gauge",
          startAngle: 220, // 圆环左边起始位置  220
          endAngle: -40, // 圆环右边边起始位置  -40
          min: 0,
          max: this.TotalData,
          splitNumber: this.TotalData, // 仪表盘刻度的分割段数
          radius: "41%",
          center: centerArr[index],
          legendHoverLink: true,
          // tooltip: {
          //   formatter: function (params) {
          //     // 不能删，提示
          //     let str = params[0].name + ":"; // 标题的名字
          //     params.filter(function (item) {
          //       // console.log(item.componentSubType); //外面可以拿到item.color,只要在if条件item.componentSubType == "bar"里面就拿不到了，或者item.componentSubType == "pictorialBar",可以拿到
          //       if (item.componentSubType == "bar") {
          //         // 在bar里面可以拿到item.seriesName(符合企业数量) item.value（值）
          //         let p = "";
          //         for (let i = 0; i < item.color.colorStops.length; i++) {
          //           const element = item.color.colorStops[i];
          //           // console.log(element);
          //           if (element !== null) {
          //             // 判断里面是否为空
          //             p = element;
          //             break;
          //           }
          //         }
          //         // console.log(p.color); //可以打印出结果
          //         str +=
          //           "<br/>" +
          //           "<span style='display:inline-block;width:10px;height:10px;border-radius:10px;margin-right:3px;background-color:" +
          //           p.color +
          //           ";'></span>" +
          //           item.seriesName +
          //           "：" +
          //           item.value; // 拼接数据
          //       }
          //     });
          //     return str;
          //   },
          // },

          // 标题样式
          title: {
            show: true,
            // text: '你好',

            // height: 20,
            // width: 95,
            height: this.titleFontSize / 2.18, //20
            width: this.titleFontSize / 0.44, //95

            offsetCenter: [0, "96%"],

            // borderWidth: 2,
            borderWidth: this.titleFontSize / 21.8,

            borderColor: "#066cc6",
            backgroundColor: "#060a54",

            // borderRadius: 200,
            borderRadius: this.titleFontSize / 0.218,

            // padding: [12, 25, 8, 25],
            // padding: [0, 0, 0, 0],
            // textStyle: {
            //   color: "#fff",

            // fontSize: 14,
            fontSize: this.titleFontSize / 3.1,

            //   fontWeight: "bold",
            // },
            shadowBlur: 8, // 阴影模糊大小.该属性配合 shadowColor,shadowOffsetX, shadowOffsetY 一起设置图形的阴影效果。
            shadowColor: "#0868c2", // 阴影颜色
            // boxShadow: inset,
            shadowOffsetX: "0", // 阴影水平方向上的偏移距离
            shadowOffsetY: "0", // 阴影垂直方向上的偏移距离
            // textStyle: {
              // 标题文字样式设置
              color: "#01bbd5", // 标题文字颜色
              // fontSize: 14, //标题文字大小
              // lingHeight: 19,
              fontWeight: "bold", // 标题文字加粗
              fontFamily:
                '"Microsoft YaHei","微软雅黑","PingFang SC","Helvetica Neue",Helvetica,"Hiragino Sans GB",sans-serif,Arial',
            // },
          },
          // 仪表盘指针。
          pointer: {
            // 这个show属性好像有问题，因为在这次开发中，需要去掉指正，我设置false的时候，还是显示指针，估计是BUG吧，我用的echarts-3.2.3；希望改进。最终，我把width属性设置为0，成功搞定！
            show: false,
            // 指针长度
            // length: "90%",
            // width: 0,
          },
          // 刻度标签。
          axisLabel: {
            show: false,
          },
          // 刻度样式。
          axisTick: {
            show: false,
          },
          // 分隔线样式。
          splitLine: {
            show: false,
          },
          // 蓝色圆环
          progress: {
            show: true, // 是否显示进度条
            roundCap: true, // 是否在两端显示成圆形

            // width: 14, // 进度条宽度
            width: this.titleFontSize / 3.1,

            itemStyle: {
              // 上层圆环，显示数据
              // color: {
              //   //图形渐变颜色方法，四个数字分别代表，右，下，左，上，offset表示0%到100%
              //   type: "linear",
              //   x: 0,
              //   y: 0,
              //   x2: 0, //从左到右 0-1
              //   y2: 1,
              //   colorStops: [
              //     {
              //       offset: 0,
              //       color: "#F2684F", //黄色进度条渐变颜色
              //     },
              //     {
              //       offset: 1,
              //       color: "#FFCA4A", //黄色进度条渐变颜色
              //     },
              //   ],
              // },
              color: {
                // 图形渐变颜色方法，四个数字分别代表，右，下，左，上，offset表示0%到100%
                type: "linear",
                x: 0,
                y: 0,
                x2: 0, // 从左到右 0-1
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: colorArr[index][0], // 黄色进度条渐变颜色
                  },
                  {
                    offset: 1,
                    color: colorArr[index][1], // 黄色进度条渐变颜色
                  },
                ],
              },
            },
          },
          // 灰色圆环
          axisLine: {
            show: true,
            roundCap: true, // 是否在两端显示成圆形
            // 属性lineStyle控制线条样式
            lineStyle: {
              // width: 14, // 轴线宽度
              width: this.titleFontSize / 3.1,
              color: [[1, "#023275"]],
            },
          },
          // 仪表盘详情，用于显示数据。
          detail: {
            show: true,
            offsetCenter: [0, 2],
            // fontSize: 18, //18
            fontSize: this.titleFontSize / 2.4,
            color: "#31e4f4",
          },
          data: [
            {
              value: item.count,
              name: item.label,
            },
          ],
        };
      });

      const dateOption = {
        series: seriesArr,
      };

      this.chartInstance.setOption(dateOption);
    },

    screenAdapter() {
      this.titleFontSize = (this.$refs.operating_ref.offsetWidth / 10); //23.4   .operating_ref.offsetWidth / 10
      // console.log(titleFontSize);
      // const titleFontSize = document.querySelector('.ijn').offsetWidth
      // console.log(titleFontSize);
      // alert(titleFontSize)
      // console.log(this.$refs.operating_ref);

      // const ijn = document.querySelectorAll('.ijn')
      // console.log(ijn[0].parentElement.__vue__.$options.parent.$children[4]); //[0].parentElement.__vue__.$options.parent.$children  //[0].$parent.$children
      // const titleFontSize = ijn[0].parentElement.__vue__.$options.parent.$children[4]

      // console.log(titleFontSize);

      const adapterOption = {
        // title: {
        //   height: titleFontSize / 2.18, //20
        //   width: titleFontSize / 1.45, //95
        //   borderWidth: titleFontSize / 21.8, //2
        //   borderRadius: titleFontSize / 0.218, //200
        //   fontSize: titleFontSize / 3.1,//3.1 1.67
        // },
        // progress: {
        //   width: titleFontSize / 3.1,  //3.11   1.67
        // },
        // axisLine: {
        //   lineStyle: {
        //     width: titleFontSize / 3.1,
        //   },
        // },
        // detail: {
        //   show: true,
        //   // textStyle: {
        //     fontSize: titleFontSize / 2.4, //2.4
        //   // },
        // },
        title: {
          height: this.titleFontSize / 2.18, //20
          width: this.titleFontSize / 0.44, //95
          // borderWidth: this.titleFontSize / 21.8,
          borderRadius: this.titleFontSize / 0.218,
          fontSize: this.titleFontSize / 3.1,
        },
        progress: {
          width: this.titleFontSize / 3.1,
        },
        axisLine: {
          lineStyle: {
            width: this.titleFontSize / 3.1,
          },
        },
        detail: {
          show: true,
          fontSize: this.titleFontSize / 2.4,
        },
      };
      
      this.chartInstance.setOption(adapterOption);
      this.chartInstance.resize();
    },
  },
};
</script>

<style lang="less" scoped>
</style>
