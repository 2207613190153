//封装请求模块
import request from '@/utils/request'

// 企业服务-饼图
export const TrendData = () => {
    return request({
        method: 'GET',
        url: `/api/databoard/services`,
    })
}
// 注册资本-环形图
export const operatingData = () => {
    return request({
        method: 'GET',
        url: `/api/databoard/registered_capital`,
    })
}
// 注册资本-环形图
export const peopleData = () => {
    return request({
        method: 'GET',
        url: `/api/databoard/scale`,
    })
}
// 服务企业-List
export const listData = () => {
    return request({
        method: 'GET',
        url: `/api/databoard/order`,
    })
}
// 政策申报
export const enterpriseServiceData = () => {
    return request({
        method: 'GET',
        url: `/api/databoard/policy`,
    })
}
// 区域分布
export const FamousData = () => {
    return request({
        method: 'GET',
        url: `/api/databoard/areas`,
    })
}
// 规模人数
export const PeopleData = () => {
    return request({
        method: 'GET',
        url: `/api/databoard/scale`,
    })
}
// 西安数据中心
export const SortData = () => {
    return request({
        method: 'GET',
        url: `/api/databoard/category`,
    })
}
//西安企业总计
export const TotalData = () => {
    return request({
        method: 'GET',
        url: `/api/databoard/total`,
    })
}