<template>
  <!-- 横向柱状图 -->
  <div class="com-container">
    <div class="com-chart" ref="people_ref"></div>
  </div>
</template>

<script>
import { peopleData } from "@/api/article";
export default {
  name: "People",

  data() {
    return {
      chartInstance: null,
      allData: null,
    };
  },

  created() {
  },

  mounted() {
    this.initChart();
    this.getData();
    window.addEventListener("resize", this.screenAdapter);
    setTimeout(() => {
      this.screenAdapter(); // 界面加载完成之后，主动进行屏幕适配
    }, 1000);
  },

  destroyed() {
    window.removeEventListener("resize", this.screenAdapter); // 组件销毁的时候，需要把监听器销毁掉
  },

  methods: {
    initChart() {
      this.chartInstance = this.$echarts.init(this.$refs.people_ref);

      this.chartInstance.getZr().on("click", (parmas) => {
        let pointInPixel = [parmas.offsetX, parmas.offsetY];
        if (this.chartInstance.containPixel("grid", pointInPixel)) {
          //点击第几个柱子
          let pointInGrid = this.chartInstance.convertFromPixel(
            { seriesIndex: 0 },
            pointInPixel
          );
          // y轴数据的索引
          let yIndex = pointInGrid[1];
          let iop = this.allData.filter((item, index) => {
            if (index == yIndex) {
              return item;
            }
          });
          window.open(iop[0].target_url);
        }
      });
      const initOption = {};
      this.chartInstance.setOption(initOption);
    },

    async getData() {
      try {
        const res = await peopleData();
        this.allData = res.data.result;
      } catch (error) {
        console.log(error);
      }
      // allData进行赋值
      this.upDateChart();
    },

    upDateChart() {
      // 处理数据
      const data1 = [];
      const data2 = [];
      // console.log(data1,data2);
      this.allData.forEach((element) => {
        data1.push(element.count); // 柱状图
        data2.push(element.tag_name);
      });
      

      const dateOption = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          // textStyle: {
          // 设置提示框的对齐方式
          align: "left",
          // fontSize: 14,
          // },
        },
        grid: {
          left: "8%",
          right: "2%",
          top: "8%",
          bottom: "0%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "value",
            boundaryGap: [0, 0.01],
            splitLine: {
              // 去除背景网格线
              show: false,
            },
            axisTick: {
              // 刻度
              show: false, // 不显示刻度线
            },
            axisLine: {
              // 设置x轴线开关
              show: false,
            },
            axisLabel: {
              // 设置x轴上数字显示
              show: false,
              // textStyle: {
              color: "#86A5C3", // 刻度颜色
              //  fontSize:16  //刻度大小
              // },
            },
          }
        ],
        yAxis: [
          {
            // y轴左边文字样式
            type: "category",
            name: "单位：个",
            // inverse: true, //将echarts图倒转过来
            nameLocation: "end", // 坐标轴名称显示位置。('start''middle''center''end')
            nameGap: 0,
            nameTextStyle: {
              color: "#fff",
              // fontSize: "14",
            },
            axisTick: {
              // 刻度
              show: false, // 是否显示坐标轴刻度
              // inside: true, // 坐标轴刻度是否朝内，默认朝外
              // length: 296, // 坐标轴刻度的长度
              // lineStyle: {
              //   color: "#86A5C3", // 刻度线的颜色
              //   // width: '28', // 坐标轴刻度线宽
              //   type: "solid", // 坐标轴线线的类型（solid实线类型；dashed虚线类型；dotted点状类型）
              // },
            },
            axisLine: {
              // 设置轴线
              show: false,
            },
            axisLabel: {
              // textStyle: {
              // 文字颜色渐变
              fontFamily: "MicrosoftYaHei",
              // fontSize: "14",
              align: "right",
              // color: "#fff", //y轴文字颜色
              fontWeight: 700,
              color: function (params, index) {
                const colorValue = [
                  "#a02920",
                  "#b79f13",
                  "#01a16b",
                  "#0182a3",
                  "#0289da",
                  "#98fcbe",
                  "#c5fc27",
                  "#fff",
                ];
                return colorValue[index];
              },
            },
            data: data1,
          },
          {
            // data: [88 + "人", 182 + "人", +136 + "人", 168 + "人", 198 + "人"], //右边数据
            // y轴右边文字样式、数据
            type: "category",
            axisTick: {
              // 刻度
              show: false, // 不显示刻度线
            },
            axisLine: {
              // 设置轴线
              show: false,
            },
            axisLabel: {
              // 设置右边文字颜色
              fontFamily: "ArialMT",
              // fontSize: "14",
              // #0179c5 #0182a3 #01a16b #b79f13 #a02920
              fontWeight: 700,
              color: function (params, index) {
                const colorValue = [
                  "#a02920",
                  "#b79f13",
                  "#01a16b",
                  "#0182a3",
                  "#0289da",
                  "#98fcbe",
                  "#c5fc27",
                  "#fff",
                ];
                return colorValue[index];
              },
              // },
            },
            data: data2,
          },
        ],
        series: [
          {
            // name: "2023年",
            yAxisIndex: 1,
            type: "bar",
            // data: [88, 182, 136, 168, 198], //蓝色柱子数据
            // 关键代码
            showBackground: true, // 开启背景
            // barWidth: 13, // 不能注释
            backgroundStyle: {
              // 设置背景样式
              color: "rgba(3, 51, 117)",
              borderRadius: [50, 50, 50, 50],
            },
            itemStyle: {
              // 柱状图的颜色
              // normal: {
              // 每个柱子的颜色即为colorList数组里的每一项,如果柱子数目多于colorList的长度，则柱子颜色循环使用该数组
              color: function (params) {
                const index = params.dataIndex;
                // console.log(params);
                const colorList = [
                  // 渐变颜色的色值和透明度
                  // 透明度从0
                  [
                    "rgba(51, 127, 237)",
                    "rgba(51, 127, 237)",
                    "rgba(51, 127, 237)",
                    "rgba(51, 127, 237)",
                    "rgba(51, 127, 237)",
                    "rgba(51, 127, 237)",
                    "rgba(15,235,255,0.3)",
                    "rgba(13,94,208,0.3)",
                    "rgba(51, 127, 237)",
                    "rgba(253,103,96,0.3)",
                  ], // 到透明度1 ,如果需要不同的颜色直接修改不同颜色即可
                  [
                    "rgba(51, 232, 251)",
                    "rgba(51, 232, 251)",
                    "rgba(51, 232, 251)",
                    "rgba(51, 232, 251)",
                    "rgba(51, 232, 251)",
                    "rgba(51, 232, 251)",
                    "rgba(15,235,255,0.6)",
                    "rgba(13,94,208,0.6)",
                    "rgba(51, 232, 251)",
                    "rgba(253,103,96,0.6)",
                  ],
                ];
                return {
                  colorStops: [
                    {
                      offset: 0.1, // 颜色的开始位置
                      color: colorList[0][index], // 0% 处的颜色
                    },
                    {
                      offset: 0.8, // 颜色的结束位置
                      color: colorList[1][index], // 100% 处的颜色
                    },
                  ],
                };
              },
              // 圆柱的样式
              borderRadius: [50, 50, 50, 50],
              marginBottom: 20,
              // },
            },
            data: data1,
          },
        ],
      };
      this.chartInstance.setOption(dateOption);
    },

    screenAdapter() {
      const titleFontSize = (this.$refs.people_ref.offsetWidth / 100) * 2.35;
      // console.log(titleFontSize);
      const adapterOption = {
        tooltip: {
          // textStyle: {
          fontSize: titleFontSize / 0.75,
          // },
        },
        yAxis: [
          {
            // type: "category",
            // nameTextStyle: {
              fontSize: titleFontSize / 0.87,  //12
            // },
            axisLabel: {
              fontSize: titleFontSize / 0.87,
            },
          },
          {
            // type: "category",
            axisLabel: {
              fontSize: titleFontSize / 0.7, //14.8
            },
          },
        ],
        series: {
            // type: "bar",
            barWidth: titleFontSize / 0.75, // 调整柱子的粗细 60.16
        },
      };
      this.chartInstance.setOption(adapterOption);
      this.chartInstance.resize();
    },
  },
};
</script>

<style lang="less" scoped>
</style>
