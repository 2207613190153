import axios from 'axios'

// const request = axios.create({
// // baseURL: 'http://apitest.erp.chinawip.com', //请求地址
// baseURL: 'http://api.erp.chinawip.com', //正式环境
// })

// const baseURL = process.env.NODE_ENV === 'exploit' ? process.env.VUE_APP_API_BASEURL : process.env.NODE_ENV === 'test' ? process.env.VUE_APP_TEST_BASEURL
// : process.env.VUE_APP_PROD_BASEURL;

// const baseURL = ''
// if (process.env.NODE_ENV === 'test') {
//     baseURL = process.env.VUE_APP_TEST_BASEURL
// } else if (process.env.NODE_ENV === 'exploit') {
//     baseURL = process.env.VUE_APP_API_BASEURL
// } else if (process.env.NODE_ENV === 'production') {
//     baseURL = process.env.VUE_APP_PROD_BASEURL
// }

const request = axios.create({
    baseURL: process.env.VUE_APP_API_BASEURL
});

export default request

//配置请求拦截器