<template>
  <div class="com-container">
    <div class="com-chart" ref="people_ref">
      <table class="table_stick">
        <tr class="one">
          <th>企业名称</th>
          <th>服务名称</th>
        </tr>
      </table>

        <div @click="handleButton($event)">
          <vue-seamless-scroll
            :data="companyLisDataLemght"
            class="warp"
            ref="seamlessScroll"
            :class-option="classOption"
          >
            <table class="table_data">
              <!-- <caption></caption> -->
              <tr v-for="(item, index) in companyLisData" :key="index" class='labelIndex' :id='index'>
                <td v-text="item.company_name"></td>
                <td v-text="item.service_name"></td>
              </tr>
            </table>
          </vue-seamless-scroll>
        </div>

    </div>
  </div>
</template>

<script>
import { listData } from "@/api/article";
export default {
  name: "List",

  data() {
    return {
      companyLisData: null,
      companyLisDataLemght: [],
    };
  },

  mounted() {
    this.companyList(); //服务企业数据
    this.time();
  },
  destroyed () {

  },
  computed: {
    classOption() {
      return {
        step: 0.3, // 数值越大速度滚动越快
        // limitMoveNum: 1, // 开始无缝滚动的数据量 this.list
        // hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        // singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        // waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      }
    }
  },

  watch: {},

  methods: {
    //获取数据
    async companyList() {
      try {
        const res = await listData();
        this.companyLisData = res.data.result;
        this.companyLisData.forEach((item,index,arr) => {
          if (item) {
            this.companyLisDataLemght.push(index)
            return
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    destroyed() {
      clearTimeout(time())
    },
    time() {
      setTimeout(() => {
        this.companyLisDataLemght.push();
        this.$refs.seamlessScroll.reset();
      }, 2000);
    },

    // 处理鼠标点击到哪条，可添加跳转
		handleButton(e) {
			console.log(e, ' =====> e')
			console.log(e.target.parentNode.cells,' =====> e.path')
		}

  },
};
</script>

<style lang="less" scoped>
/deep/.table_stick {
  width: 100%;
  height: 20%;
  border-collapse: collapse;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  z-index: 999;
  .one {
    // height: 2.2rem;
    height: 30px;
    color: #fff;
    background-color: rgba(4, 55, 110);
    cursor:pointer;
  }
  tr {
    width: 50%;
    border: 1px solid rgba(2, 78, 143);
    background-color: rgba(4, 40, 88);
    color: rgba(2, 153, 186);
    th:nth-child(1) {
      width: 46%;
      border: 1px solid rgba(2, 78, 143);
    }
  }
}
.warp {
    width: 100%;
    height: 80%;
    overflow: hidden;
    cursor:pointer;
  .table_data {
    border-collapse: collapse;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    tr {
      border: 1px solid rgba(2, 78, 143);
      background-color: rgba(4, 40, 88);
      color: rgba(2, 153, 186);
     /deep/ td:nth-child(1) {
        width: 46%;
        height: 20px;
        border: 1px solid rgba(2, 78, 143);
      }
     /deep/ td:nth-child(2) {
        height: 20px;
        border: 1px solid rgba(2, 78, 143);
      }
    }
  }
}
</style>