import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
// import operatingPage from '@/views/operatingPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/Home'
  },
  {
    path: '/Home',
    name: 'Home',
    component: ()=> import('@/views/Home.vue'),
    beforeRouteLeave(to, from, next) {
      // 在组件即将离开时执行销毁组件的逻辑
      this.$destroy(); // 销毁组件实例

      next(); // 继续路由跳转
    }
  },
  {
    path: '/housingList',
    name: 'housingList',
    component: ()=> import('@/views/housingList.vue'),
    beforeRouteLeave(to, from, next) {
      // 在组件即将离开时执行销毁组件的逻辑
      this.$destroy(); // 销毁组件实例

      next(); // 继续路由跳转
    }
  },
  {
    path: '/PolicyMatching',
    name: 'PolicyMatching',
    component: ()=> import('@/views/PolicyMatching.vue'),
    beforeRouteLeave(to, from, next) {
      // 在组件即将离开时执行销毁组件的逻辑
      this.$destroy(); // 销毁组件实例

      next(); // 继续路由跳转
    }
  }
  //圆环图
  // {
  //   path: '/operatingPage',
  //   name: 'operatingPage',
  //   component: operatingPage,
  // },
]

const router = new VueRouter({
  routes
})

export default router
