import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currDbSource: {},
  },
  getters: {

  },
  mutations: {
    saveCurrDbSource(state,currDbSource) {
      state.currDbSource = currDbSource;
    }
  },
  actions: {

  },
  modules: {
    
  }
})
