import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store/index'
import axios from 'axios'
import '@/style/gloab.css'
import * as echarts from 'echarts'
import './assets/images/font_3849069_oiprudwcvjs/iconfont.css'
import '@/assets/images/font_3849069_oiprudwcvjs/iconfont.json'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

// import 'amfe-flexible'
import scroll from 'vue-seamless-scroll'
Vue.prototype.$axios = axios
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
Vue.use(scroll)
Vue.use(ElementUI)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')