<template>
  <!-- 柱状图and折线图 -->
  <div class="com-container">
    <div class="com-chart" ref="Famous_ref"></div>
  </div>
</template>

<script>
import { FamousData } from "@/api/article";
// 默认样式
export default {
  name: "Famous",
  data() {
    return {
      chartInstance: null,
      allData: null,
    };
  },

  mounted() {
    this.initChart();
    this.getData();
    this.screenAdapter();
    window.addEventListener("resize", this.screenAdapter);
  },

  destroyed() {
    window.removeEventListener("resize", this.screenAdapter);
  },

  methods: {
    initChart() {
      this.chartInstance = this.$echarts.init(this.$refs.Famous_ref);

      this.chartInstance.getZr().on("click", (parmas) => {
        let pointInPixel = [parmas.offsetX, parmas.offsetY];
          if (this.chartInstance.containPixel("grid", pointInPixel)) {
            //点击第几个柱子
            let pointInGrid = this.chartInstance.convertFromPixel(
              { seriesIndex: 0 },
              pointInPixel
            );
            // 也可以通过params.offsetY 来判断鼠标点击的位置是否是图表展示区里面的位置
            // 也可以通过name[xIndex] != undefined，name是x轴的坐标名称来判断是否还是点击的图表里面的内容
            // x轴数据的索引
            let xIndex = pointInGrid[0];
            // console.log(xIndex); //打印出第几个柱子
            let iop = this.allData.filter((item, index) => {
              // console.log(index);
              if (index == xIndex) {
                return item;
              }
            });
            window.open(iop[0].target_url);
          }
      });
      const initOption = {};
      this.chartInstance.setOption(initOption);
    },

    async getData() {
      try {
        const res = await FamousData();
        this.allData = res.data.result;
      } catch (err) {
        console.log(err);
      }
      this.upDateChart();
    },

    upDateChart() {
      const FamousAxisData = [];
      const data1 = [];
      const data2 = [];
      this.allData.forEach((element) => {
        FamousAxisData.push(element.area); // y x坐标文字
        data1.push(element.total); // 柱状图
        data2.push(element.total); // 折线图
      });

      // 处理数据
      const FamousOption = {
        color: ["#218AFF", "#47B8D9"], // 文字的颜色
        // 提示
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#3394f0",
            },
          },
        },
        // 边距
        grid: {
          left: "3%",
          right: "4%",
          bottom: "8%",
          top: "32",
          containLabel: true,
        }, // 整体gird表示图的位置

        xAxis: [
          // x坐标的相关配置
          {
            type: "category",
            data: FamousAxisData, // 对数据的渲染
            axisLabel: {
              // textStyle: {
                // fontSize: 13, // 更改坐标轴文字大小
                fontFamily: "MicrosoftYaHei",
                color: "#fff",
              // },
            },
            axisPointer: {
              // 鼠标移入的效果
              type: "line", // 线
              lineStyle: {
                color: "#6b6b6b", // 线的颜色
                type: "solid",
                // width: 40,
              },
            },
            axisTick: {
              // x轴是否显示刻度线
              show: false,
            },
            axisLine: {
              // 是否显示x轴最下面的线，以及线的样式
              show: true,
              lineStyle: {
                color: "#818286",
              },
            },
          },
        ],
        yAxis: [
          // 由于是折柱混合，所以要有两个y轴，那么就要对两个y轴进行属性样式设定
          {
            // 左边y轴
            type: "value",

            name: "单位：个",
            nameLocation: "end", // 坐标轴名称显示位置。('start''middle''center''end')
            nameTextStyle: {
              color: "#fff",
            },
            axisLabel: {
              // textStyle: {
                fontFamily: "MicrosoftYaHei",
                color: "#fff",
              // },
            },

            axisTick: {
              show: true, // 是否显示坐标轴刻度
              inside: true, // 坐标轴刻度是否朝内，默认朝外
              length: 1, // 坐标轴刻度的长度
            },
            axisTick: {
              // 是否显示刻度线
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                // 画y轴的线
                type: "dashed",
                color: "#033375",
              },
            },
            axisLine: {
              // y轴最左边的线是否显示
              show: false,
              lineStyle: {
                color: "#989898", // y轴最左边的单位的样式
              },
            }, // y轴最左边的线的样式
          },
          // y轴右边线的样式，和左边同理
          {
            type: "value",
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#989898",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
              },
            },
            axisLabel: {
              // textStyle: {
                // fontSize: 13, // 更改坐标轴文字大小
                fontFamily: "MicrosoftYaHei",
                color: "reds",
              // },
            },
            // axisLabel: {
            //   formatter: function (value) {
            //     return value;
            //   },
            // },
          },
        ],
        series: [
          // 圆柱和曲线的绘制
          {
            name: "单位",
            type: "bar", // 圆柱
            color: "#1362FE",
            color: {
              type: "linear",
              x: 0, // 右
              y: 0, // 下
              x2: 0, // 左
              y2: 1, // 上
              colorStops: [
                {
                  offset: 0,
                  color: "#33e8fb", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#337fed", // 100% 处的颜色
                },
              ],
            },
            data: data1,
          },
        ],
      };
      this.chartInstance.setOption(FamousOption);
    },

    screenAdapter() {
      const titleFontSize = (this.$refs.Famous_ref.offsetWidth / 100) * 2.35; // 45.12
      const adapterOption = {
        // 大标题
        title: {
          // textStyle: {
            fontSize: titleFontSize,
            lineHeight: "titleFontSize / 0.6",
          // },
        },
        // 工具
        toolbox: {
          itemSize: titleFontSize / 0.6,
          feature: {
            itemSize: titleFontSize / 2, // 工具栏 icon 的大小
            itemGap: titleFontSize / 3, // 工具栏 icon 每项之间的间隔
          },
        },

        xAxis: {
          type: "category",
          axisLabel: {
            // textStyle: {
              fontSize: titleFontSize / 0.8, // 更改坐标轴文字大小
            // },
          },
        },
        yAxis: [
          {
            type: "value",
            nameTextStyle: {
              fontSize: titleFontSize / 0.8, // y轴单位字体大小
            },
            axisLabel: {
              // textStyle: {
                fontSize: titleFontSize / 0.8, // 更改y轴坐标轴文字大小
              // },
            },
          },
          {
            type: "value",
            axisLabel: {
              // textStyle: {
                fontSize: titleFontSize / 0.8, // 更改y轴坐标轴文字大小
              // },
            },
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: titleFontSize / 0.75, // 圆柱的宽
            itemStyle: {
              // emphasis: {
                borderRadius: titleFontSize,
              // },
              // normal: {
                borderRadius: [
                  titleFontSize / 0.6,
                  titleFontSize / 0.6,
                  0,
                  0,
                ],
                marginBottom: titleFontSize / 1.5,
              // },
            },
          },
        ],
      };
      this.chartInstance.setOption(adapterOption);
      this.chartInstance.resize();
    },
  },
};
</script>

<style lang="less" scoped>
</style>
